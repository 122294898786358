import React, { useEffect, useState } from "react";
import Bell from "../../../assets/svg/Assessments/Bell.svg";
import Calendar from "../../../assets/svg/Assessments/Calendar White.svg";
import { useHistory } from "react-router-dom";

import Loader from "../../reusables/Loader";
import * as api from "../../../api";
import usePracticeHook from "../../../practice";
import { releaseNotesVersion } from "../release_notes/versions";

const Events = () => {
  // const { authState } = useOktaAuth();
  // const accessToken = authState.accessToken;
  const authState = JSON.parse(localStorage.getItem("auth0-token-storage"));
  const accessToken = authState?.access_token;
  const history = useHistory();
    const { hasPhysioPractice } = usePracticeHook();
  const [loader, setLoader] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  var months = new Array(
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec"
  );

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        let announcementData = await api.getAnnouncements(accessToken, 1, 200);
        if (
          !announcementData ||
          !announcementData.data ||
          !announcementData.data.data ||
          !announcementData.data.data.length === 0 ||
          !announcementData.data.data[0] ||
          !announcementData.data.data[0].FacilityBirthdays
        )
          return;
        if (announcementData.length === 0) return;
        let birthdayArray =
          announcementData.data.data[0].FacilityBirthdays.sort((a, b) => {
            return (
              new Date(a.BirthDate).getDate - new Date(b.BirthDate).getDate
            );
          });

        setAnnouncements(birthdayArray.slice(0, 7));
      } catch (error) {
        console.log("asdfweasdfef", error);
        if (
          error?.response?.status === 401 &&
          error?.response?.data?.errors?.length > 0 &&
          error?.response?.data?.errors[0]?.message === "Invalid Token"
        ) {
          handleExpiration();
        }
      } finally {
        setLoader(false);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleExpiration = () => {
		localStorage.removeItem("auth0-token-storage");
		localStorage.removeItem("user-claims");
		history.push("/login");
	};

  return (
    <div className="container pb-0 mb-0">
      <div
        className="orange-bg-1 text-light py-3 my-0 d-flex justify-content-between align-items-center align-content-center align-self-center"
        style={{
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
        }}
      >
        <div className="d-flex justify-content-center align-items-center m-auto align-content-center align-self-center">
          <img
            src={Calendar}
            alt="alert"
            style={{ height: "22px", color: "#ffffff", marginRight: "10px" }}
            className="announcement-icon d-flex align-content-center align-items-center align-self-center my-auto"
          />
          <h5 className="text-light align-items-center justify-content-center d-flex m-auto">
            Events
          </h5>
        </div>
      </div>
      <div
        className="p-0 bg-white border"
        style={{
          borderBottomLeftRadius: "25px",
          borderBottomRightRadius: "25px",
          backgroundColor: "white",
        }}
      >
        <div
          className="mx-0 px-2 py-3"
          style={{
            minHeight: "240px",
          }}
        >
          {
            loader ? (
              <div
                className="h-100 w-100 align-items-center d-flex"
                style={{
                  minHeight: "240px",
                }}
              >
                <Loader />
              </div>
            ) : (
              // announcements && announcements.length > 0 ?
              <>
                {announcements && announcements.length > 0 && (
                  <h6 className="d-flex mx-3 my-2">Birthdays</h6>
                )}
                {announcements &&
                  announcements.length > 0 &&
                  announcements.map((announcement, index) => {
                    let date = new Date(announcement.BirthDate);
                    let month = date.getMonth();
                    let day = date.getDate();

                    return (
                      <div key={index}>
                        <div className="d-flex align-items-center py-0 ml-3 mr-2 my-2">
                          <div className="pr-3 pl-2 ml-1">
                            <div className="orange-bg-1 d-flex flex-column text-light text-align-center justify-content-center rounded">
                              <p className="d-flex justify-content-center h6 px-1 mx-3 mt-2 mb-0">
                                {months[month].toUpperCase()}
                              </p>
                              <p className="d-flex justify-content-center h6 px-1 mx-3 mt-0 mb-2">
                                {day}
                              </p>
                            </div>
                          </div>
                          <div className="w-100 text-left mt-2 mb-2 ml-0">
                            <p
                              className="h6 mx-0 my-1 font-weight-bold"
                              style={
                                month ===
                                  currentDate.toDateString().split(" ")[1] &&
                                day === currentDate.toDateString().split(" ")[2]
                                  ? { fontSize: "17px", color: "orange" }
                                  : { fontSize: "17px" }
                              }
                            >
                              {announcement.FullName}
                            </p>
                            {/* <div className="d-flex align-items-center">
                            <p style={{ fontSize: "14px", color: "grey" }}>
                              {announcement.FullName}
                            </p>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {hasPhysioPractice ? null : (
                  <>
                    {<h6 className="d-flex mx-3 mb-2 mt-3">Release Notes</h6>}
                    {releaseNotesVersion && releaseNotesVersion.slice(0).reverse().map((data, index) => (
                    <div className="d-flex align-items-center py-0 ml-3 mr-2 my-2" key={index}>
                      <div className="pr-3 pl-2 ml-1 font-weight-bold">
                        <a href={`/releaseNotes/${data.version}`} style={{ color: "orange" }}>
                          HapiPartner {data.version} - {data.releaseDate}
                        </a>
                      </div>
                    </div>
                    ))}
                  </>
                )}
              </>
            )
            // : (
            //   <>
            //     <div
            //       className="h-100 w-100 align-items-center d-flex text-center justify-content-center my-auto"
            //       style={{
            //         fontSize: "15px",
            //         letterSpacing: "1px",
            //         fontWeight: "bolder",
            //         opacity: 0.2,
            //         minHeight: "240px",
            //         backgroundColor: "rgba(0, 0, 0, 0.07)",
            //         borderRadius: "25px",
            //       }}
            //     >
            //       No Events
            //     </div>
            //   </>
            // )
          }
        </div>
      </div>
    </div>
  );
};

export default Events;
